@import url('https://fonts.googleapis.com/css2?family=Grandstander:wght@500&family=Merriweather:wght@400;700&family=Noto+Sans&display=swap');

$base-font-family: 'Noto Sans', sans-serif;
$content-width: 1100px;
$base-font-size: 16px;

@import "minima";

html, body, .container {
  height: 100%;
  width: 100%;
}

body {
  background-color: transparent;
  background-image: url("/assets/musicnotes-1.jpg");
  background-repeat: repeat;
  background-attachment: fixed;

}

article {
  background-color: white;
  padding: 55px;
}

p {
  line-height: 25px;
}

.post-title {
  font-family: 'Merriweather', serif;;
}

main {
  margin-top: 100px;
}

.site-header {
  border: none;
  background-color: darkred;
  font-size: 1.3em;
  min-height: 100px;

  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;

  display: flex;
  align-items: center;
  .wrapper {
    width: 100%;
  }

  .site-title{
    color: white;
    font-size: 45px;
    font-family: 'Grandstander', cursive;
  }

  .site-nav {
    @include media-query($on-palm) {
      position: fixed;
    }

    .page-link {
      color: white;
      font-size: 23px;
      font-family: 'Merriweather', serif;
      padding-left: 38px;
      text-decoration: none;

      &:hover {
        @media screen and (min-width: $on-palm) {
          &:before {
            display: inline-block;
            content: ' ';
            background-image: url(/assets/note.svg);
            background-size: 42px 42px;
            height: 41px;
            width: 38px;
            margin-bottom: -11px;
          }
          padding-left: 0px;
        }
      }

      @include media-query($on-palm) {
        color: black;
        line-height: 30px;
      }
    }
  }
}

.image-left {
  img {
    float: left;
    padding-right: 25px;
  }
  &::after {
    content: " ";
    clear: both;
    display: block;
  }
}

.image-right {
  img {
    float: right;
    padding-left: 25px;
  }
  &::after {
    content: " ";
    clear: both;
    display: block;
  }
}

.video-left {
  margin-bottom: 25px;
  iframe {
    max-width: 100%;
    float: left;
    margin-right: 25px;
    border: none;
  }
  &::after {
    content: " ";
    clear: both;
    display: block;
  }
}

